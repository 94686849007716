.footer-logo-wrapper {
    z-index: 3;
    margin-top: 25px;
}
.footer-logo-wrapper ul {
    display: flex;
    align-items: center;
}
.footer-logo-wrapper ul li, .footer-logo-wrapper ul li a {
    display: inline-block;
}
.footer-logo-wrapper ul li:not(:last-child) {
    margin-right: 50px;
}
.footer-logo-wrapper ul li a img {
    width: 180px;
    height: auto;
}
.accessibility-text {
    z-index: 3;
    margin-top: 40px;
}
.accessibility-text a {
    text-decoration: none;
    display: inline-block;
    text-transform: capitalize;
    color: rgb(140, 170, 157);
    font-size: 20px;
    text-decoration: underline;
}

@media (max-width: 1199.98px) {
    .footer-logo-wrapper {
        margin-top: 28px;
    }
}
@media (max-width: 991.98px) {
    .footer-logo-wrapper {
        margin-top: 0;
    }
    .footer-logo-wrapper ul li a img {
        width: 160px;
    }
    .accessibility-text {
        margin-top: 35px;
    }
    .accessibility-text a {
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .footer-logo-wrapper {
        margin-top: 10px;
    }
    .footer-logo-wrapper ul li:not(:last-child) {
        margin-right: 40px;
    }
    .footer-logo-wrapper ul li a img {
        width: 150px;
    }
}

@media (max-width: 575.98px) {
    .footer-logo-wrapper {
        margin-top: 5px;
    } 
    .footer-logo-wrapper ul li a img {
        width: 120px;
    }
    .footer-logo-wrapper ul li:not(:last-child) {
        margin-right: 30px;
    }
}