.background-img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.background-img img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}