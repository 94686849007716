@font-face {
  font-family: Nunito;
  src: url(./fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: Turret-Bold;
  src: url(./fonts/TurretRoad-Bold.ttf);
}

body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
}
