.main-heading {
    font-size: 24px;
    color: rgb(255, 255, 255);
    margin: 0;
    font-weight: 400;
    line-height: normal;
}
.main-heading span.color-text {
    display: inline-block;
    color: rgb(140, 170, 157);
}
.main-heading span.hover-text {
    cursor: pointer;
    position: relative;
}
.main-heading .hover-text-container {
    background-color: rgba(53, 62, 59, 1);
    text-align: left;
    width: 550px;
    position: absolute;
    border-radius: 5px;
    padding: 15px;
    top: 100%;
    left: 0;
    z-index: 5;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
}
.main-heading .hover-text-container.school-text {
    width: 350px;
}
.main-heading span.hover-text:hover .hover-text-container {
    visibility: visible;
    opacity: 1;
}
.main-heading .hover-text-container h5 {
    color: rgb(255, 255, 255);
    font-size: 13px;
    margin: 0 0 8px;
}
.main-heading .hover-text-container p {
    color: rgb(255, 255, 255);
    font-size: 13px;
    margin: 2px 0;
}
.main-heading.heading-space {
    margin-top: 25px;
}

@media (max-width: 1680px) {
    .main-heading.breakline br {
        display: none;
    }
}
@media (max-width: 1199.98px) {
    .main-heading {
        font-size: 20px;
    }
}
@media (max-width: 991.98px) {
    .main-heading .hover-text-container {
        width: 330px;
    }
    .main-heading .hover-text-container.school-text {
        width: 250px;
    }
    .main-heading.breakline br {
        display: block;
    }
    .main-heading.heading-space {
        margin-top: 20px;
    }
}

@media (max-width: 767.98px) {
    .main-heading {
        font-size: 18px;
    }
}

@media (max-width: 575.98px) {
    .main-heading .hover-text-container {
        width: 280px;
        padding: 10px 12px;
    }
    .main-heading .hover-text-container.school-text {
        width: 200px;
    }
}
