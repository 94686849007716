.heading-wrapper {
    flex-basis: 42%;
    max-width: 100%;
}
.timeline-image-wrapper {
    flex-basis: 45%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}
.timeline-image-wrapper .image-wrapper {
    height: 700px;
    min-height: 700px;
    max-height: 700px;
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.timeline-image-wrapper .image-wrapper img {
    max-width: 100%;
    display: inline-block;
    height: auto;
    max-height: 100%;
}
.timeline-wrapper {
    flex-basis: 100%;
    max-width: 100%;
    display: flex;
    margin-top: 70px;
}
.timeline-wrapper .timeline {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.timeline .single-timeline {
    flex-grow: 1;
    position: relative;
    display: flex;
}
.timeline .single-timeline::after {
    content: "";
    background-color: rgb(140, 170, 157);
    order: 2;
    flex: 1 1 auto;
    align-self: center;
    height: 1px;
}
.timeline .single-timeline:last-child {
    flex-grow: 0;
}
.single-timeline .timeline-circle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid rgb(140, 170, 157);
    position: relative;
    cursor: pointer;
    display: inline-block;
}
.single-timeline .timeline-circle::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: rgb(140, 170, 157);
    border-radius: 50%;
    transition: all 0.3s;
}
.timeline .single-timeline.active .timeline-circle::before {
    width: 28px;
    height: 28px;
}
.single-timeline span.timeline-text {
    position: absolute;
    top: calc(100% + 4px);
    left: 50%;
    transform: translateX(-50%);
    color: rgb(255, 255, 255);
    font-size: 17px;
    display: inline-block;
    cursor: pointer;
}
.timeline .single-timeline span.timeline-circle, .timeline .single-timeline span.year-text {
    display: none;
}

@media (max-width: 1919.98px) {
    .timeline-image-wrapper {
        flex-basis: 46%;
    }
    .timeline-image-wrapper .image-wrapper {
        height: 650px;
        min-height: 650px;
        max-height: 650px;
    }
    .single-timeline .timeline-circle, .timeline .single-timeline.active .timeline-circle::before {
        width: 24px;
        height: 24px;
    }
    .single-timeline .timeline-circle::before {
        width: 10px;
        height: 10px;
    }
    .single-timeline span.timeline-text {
        font-size: 16px;
    }
}
@media (max-width: 1680px) {
    .timeline-image-wrapper {
        flex-basis: 42%;
    }
    .timeline-image-wrapper .image-wrapper {
        height: 600px;
        min-height: 600px;
        max-height: 600px;
    }
    .timeline-wrapper {
        margin-top: 55px;
    }
    .single-timeline .timeline-circle, .timeline .single-timeline.active .timeline-circle::before {
        width: 22px;
        height: 22px;
    }
    .single-timeline span.timeline-text {
        font-size: 15px;
    }
}
@media (max-width: 1600px) {
    .timeline-image-wrapper .image-wrapper {
        height: 550px;
        min-height: 550px;
        max-height: 550px;
    }
    .single-timeline .timeline-circle, .timeline .single-timeline.active .timeline-circle::before {
        width: 18px;
        height: 18px;
    }
    .single-timeline .timeline-circle::before {
        width: 8px;
        height: 8px;
    }
    .single-timeline span.timeline-text {
        font-size: 14px;
    }
}
@media (max-width: 1366px) {
    .timeline-image-wrapper {
        flex-basis: 45%;
    }
    .timeline-image-wrapper .image-wrapper  {
        height: 350px;
        min-height: 350px;
        max-height: 350px;
    }
    .timeline-wrapper {
        margin-top: 45px;
    }
}
@media (max-width: 1199.98px) {
    .timeline-image-wrapper {
        flex-basis: 47%;
    } 
    .timeline-wrapper {
        margin-top: 40px;
    }
    .single-timeline .timeline-circle, .timeline .single-timeline.active .timeline-circle::before {
        width: 15px;
        height: 15px;
    }
    .single-timeline .timeline-circle::before {
        height: 7px;
        width: 7px;
    }
    .single-timeline span.timeline-text {
        font-size: 12px;
        top: calc(100% + 3px);
    }  
}

@media (max-width: 991.98px) {
    .heading-wrapper {
        flex-basis: 100%;
    }
    .timeline-image-wrapper {
        flex-basis: 100%;
    }
    .timeline-image-wrapper .image-wrapper {
        align-items: flex-start;
    }
    .single-timeline .timeline-circle, .timeline .single-timeline.active .timeline-circle::before {
        width: 14px;
        height: 14px;
    }
    .single-timeline .timeline-circle::before {
        height: 6px;
        width: 6px;
    }
}

@media (max-width: 767.98px) {
    .timeline-image-wrapper .image-wrapper {
        height: 300px;
        min-height: 300px;
        max-height: 300px;
    }
    .timeline-wrapper {
        margin-top: 35px;
    }
}

@media (max-width: 575.98px) {
    .timeline-wrapper {
        justify-content: flex-start;
    }
    .timeline-wrapper .timeline {
        scroll-behavior: smooth;
        overflow-x: auto;
        width: auto;
        align-items: flex-start;
    }
    .timeline-wrapper .timeline::-webkit-scrollbar {
        display: none;
    }
    .timeline-wrapper .chevron {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: rgb(34, 40, 39);
        border-radius: 50%;
    }
    .timeline-wrapper .chevron button {
        outline: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        line-height: 0;
    }
    .timeline-wrapper .chevron img {
        width: 20px;
        height: 20px;
    }
    .timeline-wrapper .chevron.left img {
        transform: rotate(90deg);
    }
    .timeline-wrapper .chevron.right img {
        transform: rotate(-90deg);
    }
    .timeline .single-timeline:not(:last-child) {
        margin-right: 23px;
    }
    .timeline .single-timeline {
        position: static;
        flex-grow: 0;
        flex-direction: column;
        align-items: center;
    }
    .timeline .single-timeline::after {
        display: none;
    }
    .timeline .single-timeline div.timeline-circle {
        display: none;
    }
    .single-timeline .timeline-circle::after {
        position: absolute;
        content: "";
        background-color: #8caa9d;
        height: 1px;
        width: 39px;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
    .single-timeline span.year-text {
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        margin-top: 3px;
    }
    .timeline .single-timeline:last-child .timeline-circle::after{
        display: none;
    }
    .timeline .single-timeline span.timeline-circle, .timeline .single-timeline span.year-text {
        display: inline-block;
    }
}
